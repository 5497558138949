import { render, staticRenderFns } from "./UpdateDeliveryDateDialog.vue?vue&type=template&id=5aa6d6f7"
import script from "./UpdateDeliveryDateDialog.vue?vue&type=script&lang=js"
export * from "./UpdateDeliveryDateDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports