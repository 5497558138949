<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="600"
  >
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="$t('order.addPersonalInfo')"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row class="pt-2">
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.socialSecurityNumber"
                :disabled="!!data.socialSecurityNumber"
                :label="$t('order.socialSecurityNumber')"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.firstName"
                :label="$t('common.firstName')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.lastName"
                :label="$t('common.lastName')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.street"
                :label="$t('address.streetName')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.city"
                :label="$t('address.city')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.zipCode"
                :label="$t('address.postalCode')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="ssnInfo.country"
                :label="$t('address.country')"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mt-2 pb-4">
        <v-btn
          class="primary px-10 mr-4"
          :disabled="!validForm"
          @click="addInfo"
        >
          {{ $t("common.add") }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddSsnInfoDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    dialog (value) {
      if (!value) {
        return
      }

      this.ssnInfo = {
        socialSecurityNumber: this.data.socialSecurityNumber || '',
        firstName:  this.data.firstName || '',
        lastName:  this.data.lastName || '',
        street:  this.data.street || '',
        city:  this.data.city || '',
        zipCode:  this.data.zipCode || '',
        country:  this.data.country || '',
      }
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: true,
      ssnInfo: {
        socialSecurityNumber: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        zipCode: '',
        country: '',
      },
    }
  },

  methods: {
    addInfo() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit('set-ssn-info', this.ssnInfo)
    },
  },
}
</script>
