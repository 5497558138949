const OrderStatusEnum = Object.freeze({
  REQUEST: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  DELIVERED: 4,
  ADMIN_APPROVED: 5,
  CANCELED: 6,
})

export default OrderStatusEnum
