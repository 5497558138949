<template>
  <base-card class="order-price">
    <v-card-text>
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2">
            <template v-if="isCommercial">
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="reference"
                    class="pt-0"
                    :label="$t('order.reference')"
                    :rules="requiredRules"
                    :disabled="!isEditing"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="endCustomer"
                    :label="$t('order.endCustomer')"
                    :rules="requiredRules"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="roomDescription"
                    :label="$t('order.roomDescription')"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="customerSiteReference"
                    :label="$t('order.customerSiteReference')"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0" v-if="isViewDetail">
                  <v-text-field
                    :value="util.formatDateTime(expectedDelivery)"
                    :label="$t('price.expectedDelivery')"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col v-else cols="12" md="6" class="py-0">
                  <v-menu
                    v-model="showExpectedDeliveryCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="300"
                    :disabled="!isEditing"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="expectedDelivery"
                        :label="$t('price.expectedDelivery')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :clearable="isEditing"
                        :disabled="!isEditing"
                        :rules="requiredRules"
                      />
                    </template>
                    <v-date-picker
                      v-model="expectedDelivery"
                      :min="minDeliveryDate"
                      @input="showExpectedDeliveryCalendar = false"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-checkbox
                    v-if="isEditing"
                    v-model="wantToOrderHouse"
                    :label="$t('order.wantToOrderHouse')"
                    hide-details
                    class="mt-0"
                  />
                  <div class="text-16" v-else>
                    <label class="font-weight-bold">{{ $t("order.wantToOrderHouse") }}</label><br>
                    <span>
                      {{ wantToOrderHouse ? $t("common.yes") : $t("common.no") }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-else>
              <v-row>
                <v-col cols="12" md="6" class="py-0 d-flex align-center" v-if="isViewDetail">
                  <v-text-field
                    :value="util.formatDateTime(expectedDelivery)"
                    :label="$t('price.expectedDelivery')"
                    :disabled="!isEditing"
                  />
                  <v-btn
                    color="primary"
                    fab
                    x-small
                    dark
                    v-if="isAllowUpdatingDeliveryDate"
                    @click="showDeliveryDateDialog = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" md="6" class="py-0" v-else>
                  <v-menu
                    v-model="showExpectedDeliveryCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="300"
                    :disabled="!isEditing"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="expectedDelivery"
                        :label="$t('price.expectedDelivery')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :clearable="isEditing"
                        :rules="requiredRules"
                      />
                    </template>
                    <v-date-picker
                      v-model="expectedDelivery"
                      :min="minDeliveryDate"
                      @input="showExpectedDeliveryCalendar = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" class="py-0 d-flex align-center">
                  <v-text-field
                    v-model="socialSecurityNumber"
                    :label="$t('order.socialSecurityNumber')"
                    :rules="isNoNeedSsnValidation ? undefined : securityNumberRules"
                    :disabled="!isEditing"
                    @input="validateSocialSecurityNumber"
                  />
                </v-col>

                <v-col v-if="(isAdminUser || isPartnerAdminUser) && isEditing" cols="12" md="6" class="pt-0 mt-md-5">
                  <v-checkbox
                    v-model="isNoNeedSsnValidation"
                    :label="$t('order.doNotValidateSsn')"
                    class="mt-0"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row
                v-for="(contact, index) in contactList"
                :key="`${index}-contact`"
              >
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="contact.email"
                    :label="$t('common.email')"
                    type="email"
                    :rules="requiredEmailRules"
                    :disabled="!isEditing"
                    @blur="onChangeEmail"
                    @change="onChangeEmail"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0" v-if="isEditing">
                  <v-text-field
                    v-model="contact.confirmEmail"
                    :label="$t('common.confirmEmail')"
                    type="email"
                    :rules="emailConfirmRules"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0" v-if="isEditing || contact.phoneNumber">
                  <v-text-field
                    v-model="contact.phoneNumber"
                    :label="$t('common.phoneNumber')"
                    :rules="phoneNumberRules"
                    :disabled="!isEditing"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0" v-if="isEditing || contact.cellPhoneNumber">
                  <v-text-field
                    v-model="contact.cellPhoneNumber"
                    :label="$t('common.cellPhoneNumber')"
                    :rules="cellPhoneNumberRules"
                    :disabled="!isEditing"
                  />
                </v-col>
              </v-row>

              <div>
                {{ $t("order.firstInvoiceWillSendToEmail") }}
                {{ $t("order.canBeDoneInYourBank") }}<br>
                {{ $t("order.remindersSentViaKivra") }}
              </div>

              <template v-if="!isEditing && activeStep !== 2">
                <div class="text-subtitle-1 font-weight-bold my-2 mt-6 underline">
                  {{ $t("order.personalInformation") }}
                </div>

                <v-row v-if="personalInfo && personalInfo.basic">
                  <v-col cols="6" md="3">
                    <div> {{ $t("common.name") }}</div>
                    <div>{{ personalInfo.basic.firstName }} {{ personalInfo.basic.lastName }}</div>
                  </v-col>

                  <v-col cols="6" md="3">
                    <div>{{ $t("order.co") }}</div>
                    <div>{{ personalInfo.basic.co }}</div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <div>{{ $t("address.address") }}</div>
                    <div>{{ personalInfo.basic.street }}, {{ personalInfo.basic.zipCode }} {{ personalInfo.basic.city }}</div>
                  </v-col>

                  <v-col cols="6" md="3">
                    <div>{{ $t("order.ssnStatus") }}</div>
                    <div>{{ personalInfo.ssnStatus }}</div>
                  </v-col>

                  <template v-if="!!personalInfo.safenodeRemark">
                    <v-col cols="6" md="3">
                      <div>{{ $t("order.blocked") }}</div>
                      <div>{{ personalInfo.safenodeRemark.blocked ? $t("common.yes") : $t("common.no") }}</div>
                    </v-col>

                    <v-col cols="6" md="6" v-if="personalInfo.safenodeRemark.summaryRemarks">
                      <div>{{ $t("order.summary") }}</div>
                      <div>
                        {{ $t("order.count") }}: {{ personalInfo.safenodeRemark.summaryRemarks.count }}
                      </div>
                      <div>
                        {{ $t("order.totalAmount") }}: {{ personalInfo.safenodeRemark.summaryRemarks.totalAmount }}
                      </div>
                      <div>
                        {{ $t("order.dateOfLatest") }}: {{ personalInfo.safenodeRemark.summaryRemarks.dateOfLatest }}
                      </div>
                    </v-col>
                  </template>
                </v-row>

                <v-btn
                  class="purple white--text px-10"
                  small
                  @click="onAddPersonalInfo"
                >
                  {{ personalInfo && personalInfo.basic ? $t("order.updatePersonalInfo") : $t("order.addPersonalInfo") }}
                </v-btn>
              </template>
            </template>

            <div class="text-subtitle-1 font-weight-bold my-2 mt-6 underline">
              {{ $t("address.address") }} - {{ addressText }}
            </div>

            <template v-if="!!selectedService">
              <div class="access-wrapper">
                <v-row class="px-4 pb-2">
                  <v-col cols="3" class="py-0 text-16">
                    {{ $t("order.accessId") }}
                  </v-col>

                  <v-col cols="5" class="py-0 text-16">
                    {{ $t("network.serviceName") }}
                  </v-col>

                  <v-col cols="2" class="py-0 text-16">
                    {{ $t("network.available") }}
                  </v-col>

                  <v-col cols="2" class="py-0 text-16">
                    {{ $t("network.connection") }}
                  </v-col>
                </v-row>

                <template>
                  <v-row class="px-4 pb-2">
                    <v-col cols="3" class="py-0 text-16">
                      {{ selectedAccess && selectedAccess.accessId || access.accessId }}
                    </v-col>

                    <v-col cols="5" class="py-0 text-16">
                      {{ selectedService.service }}
                    </v-col>

                    <v-col cols="2" class="py-0 text-16">
                      {{ formatAvailableDate(selectedService.available) }}
                    </v-col>

                    <v-col cols="2" class="py-0 text-16">
                      {{ selectedService.connection }}
                    </v-col>
                  </v-row>
                </template>
              </div>
            </template>

            <template v-if="!!selectedStokabPoint">
              <div class="access-wrapper">
                <v-row class="px-4 pb-2">
                  <v-col cols="3" class="py-0 text-16">
                    {{ $t("access.pointName") }}
                  </v-col>

                  <v-col cols="5" class="py-0 text-16">
                    {{ $t("common.type") }}
                  </v-col>

                  <v-col cols="2" class="py-0 text-16">
                    {{ $t("access.state") }}
                  </v-col>

                  <v-col cols="2" class="py-0 text-16">
                    {{ $t("access.fiberStatus") }}
                  </v-col>
                </v-row>

                <template>
                  <v-row class="px-4 pb-2">
                    <v-col cols="3" class="py-0 text-16">
                      {{ selectedStokabPoint.pointId }}
                    </v-col>

                    <v-col cols="5" class="py-0 text-16">
                      {{ util.getStokabTypeName(selectedStokabPoint.type) }}
                    </v-col>

                    <v-col cols="2" class="py-0 text-16">
                      {{ selectedStokabPoint.state }}
                    </v-col>

                    <v-col cols="2" class="py-0 text-16">
                      {{ selectedStokabPoint.fiberStatus }}
                    </v-col>
                  </v-row>
                </template>
              </div>
            </template>

            <template v-if="isCommercial">
              <div class="contact-info-wrapper mt-6">
                <div class="text-subtitle-1 font-weight-bold mt-2 mb-2 underline">
                  {{ $t("order.contactsInfo") }}
                </div>
                <v-row
                  v-for="(contact, index) in contactList"
                  :key="`${index}-contact`"
                  class="px-4"
                >
                  <v-col cols="12">
                    <div class="text-subtitle-2 font-weight-bold underline">
                      {{ $t("order.localContactPerson", { index: index + 1 }) }}
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                      v-model="contact.firstName"
                      :label="$t('common.firstName')"
                      :rules="requiredRules"
                      :disabled="!isEditing"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                      v-model="contact.lastName"
                      :label="$t('common.lastName')"
                      :rules="requiredRules"
                      :disabled="!isEditing"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="pt-0">
                    <v-select
                      v-model="contact.type"
                      :items="contactTypeList"
                      :label="$t('common.type')"
                      item-text="label"
                      item-value="id"
                      :disabled="!isEditing"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      v-model="contact.email"
                      :label="$t('common.email')"
                      :rules="emailRules"
                      type="email"
                      class="pt-0"
                      :disabled="!isEditing"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      v-model="contact.phoneNumber"
                      :label="$t('common.phoneNumber')"
                      :rules="requiredRules"
                      class="pt-0"
                      :disabled="!isEditing"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-btn
                      v-if="(index !== 0 ||
                        (index === 0 && contactList.length > 1)) &&
                        isEditing"
                      class="error px-4 mt-3"
                      small
                      @click="deleteContact(index)"
                    >
                      {{ $t("order.deleteLocalContact") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-checkbox
                      v-if="isEditing"
                      v-model="isSendDeliveryToLocalContactPersons"
                      hide-details
                      class="mt-0"
                      :label="$t('order.sendDeliveryUpdatesToLocalContactPersons')"
                    />
                    <div class="text-16 mt-2" v-else>
                      <label class="font-weight-bold">
                        {{ $t("order.sendDeliveryUpdatesToLocalContactPersons") }}
                      </label><br>
                      <span>{{
                        isSendDeliveryToLocalContactPersons
                          ? $t("common.yes")
                          : $t("common.no")
                      }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-btn
                      v-if="isEditing"
                      class="primary px-10 mt-3"
                      @click="addContact"
                    >
                      {{ $t("order.addLocalContact") }}
                    </v-btn>
                    <v-btn
                      class="purple white--text px-10"
                      small
                      @click="onAddPersonalInfo"
                    >
                      {{ personalInfo && personalInfo.basic ? $t("order.updatePersonalInfo") : $t("order.addPersonalInfo") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-else>
              <v-row class="mt-4">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :value="serviceAddress && `${serviceAddress.street} ${serviceAddress.streetNumber}` || ''"
                    :label="$t('address.address')"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :value="serviceAddress && serviceAddress.postalCode || ''"
                    disabled
                    :label="$t('address.postalCode')"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :value="serviceAddress && serviceAddress.locality || ''"
                    :label="$t('address.city')"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="6" v-if="isEditing">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="primary px-10 mr-4 mt-2"
                        small
                        @click="cancel"
                      >
                        {{ $t("order.newAddressSearch") }}
                      </v-btn>
                    </template>
                    <span>{{ $t("order.anotherAddressInstallation") }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>

            <stokab-order-info-viewer
              v-if="stokabOrderInfo.length > 0"
              :order-info="stokabOrderInfo"
            />

            <offer-inquiry-viewer
              v-if="offerInquiryProducts.length > 0"
              :offer-inquiry-products="offerInquiryProducts"
            />

            <open-network-order-info-viewer
              v-if="!!openNetworkOrderInfo"
              :order-info="openNetworkOrderInfo"
              :network-type="networkInfo.network"
              :status="orderStatus"
            />

            <div class="service-info-wrapper mt-6">
              <div class="service-info-header underline">
                <v-row>
                  <v-col cols="6" md="8" class="py-0">
                    <div class="text-subtitle-1 font-weight-bold">
                      {{ $t("order.service") }}
                      (
                        <span class="red--text">
                          {{ networkInfo && networkInfo.network }}
                        </span>
                      )
                    </div>
                  </v-col>

                  <v-col cols="3" md="2" class="py-0">
                    <span class="text-subtitle-1 font-weight-bold">
                      {{ $t("price.startCost") }}
                    </span>
                  </v-col>

                  <v-col cols="3" md="2" class="py-0">
                    <span class="text-subtitle-1 font-weight-bold">
                      {{ $t("price.monthlyCost") }}
                    </span>
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col cols="6" md="8" class="text-16">
                  {{ serviceInfoText }}
                </v-col>

                <v-col cols="3" md="2" class="text-16">
                  {{ networkStartCost }} SEK
                </v-col>

                <v-col cols="3" md="2" class="text-16">
                  {{ networkMonthlyCost }} SEK
                </v-col>
              </v-row>
            </div>

            <div class="additional-info-wrapper mt-4" v-if="addOns.length > 0 && isEditing">
              <div class="additional-info-header underline">
                <v-row>
                  <v-col cols="12" md="8" class="py-0">
                    <div class="text-subtitle-1 font-weight-bold">
                      {{ $t("order.additionalServices") }}
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div class="additional-info-content">
                <v-row
                  v-for="(addOn, index) in addOns"
                  :key="`${index}-add-on`"
                  class="py-3"
                >
                  <template v-if="addOn.type === 'checkbox'">
                    <v-col cols="6" md="8" class="py-0 pl-6">
                      <v-checkbox
                        v-model="addOnData[`value${index}`]"
                        :label="addOn.text"
                        hide-details
                        class="mt-0"
                      />
                    </v-col>

                    <v-col cols="3" md="2" class="py-0 text-16">
                      {{ addOn.nrc }} SEK
                    </v-col>

                    <v-col cols="3" md="2" class="py-0 text-16">
                      {{ addOn.mrc }} SEK
                    </v-col>
                  </template>

                  <template v-if="addOn.type === 'radioGroup'">
                    <v-col cols="12" class="py-0 pl-6">
                      <span class="text-16 font-weight-bold">
                        {{ addOn.text }}
                      </span>
                    </v-col>

                    <v-col cols="12" class="pt-1 pb-0">
                      <v-radio-group
                        v-model="addOnData[`value${index}`]"
                        column
                        hide-details
                        class="mt-0 pt-0"
                      >
                        <v-row>
                          <v-col cols="6" md="8" class="pt-0 pb-1 pl-6">
                            <v-radio
                              :label="$t('common.none')"
                              value=""
                              hide-details
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-for="(detailAddOn, detailAddonIndex) in addOn.detailAddOns"
                          :key="`${detailAddonIndex}-detail-add-on`"
                        >
                          <v-col cols="6" md="8" class="pt-0 pb-1 pl-6">
                            <v-radio
                              :value="detailAddonIndex + 1"
                              :label="detailAddOn.text"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="3" md="2" class="pt-0 pb-1 text-16">
                            {{ detailAddOn.nrc }} SEK
                          </v-col>

                          <v-col cols="3" md="2" class="pt-0 pb-1 text-16">
                            {{ detailAddOn.mrc }} SEK
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                  </template>
                </v-row>
              </div>
            </div>

            <div class="total-cost-wrapper mt-4">
              <div class="total-cost-header underline">
                <v-row>
                  <v-col cols="6" md="8" class="py-0">
                    <div class="text-subtitle-1 font-weight-bold">
                      {{ $t("order.total") }}
                    </div>
                  </v-col>
                  <v-col cols="3" md="2" class="py-0">
                    <span class="text-subtitle-1 font-weight-bold">
                      {{ $t("price.startCost") }}
                    </span>
                  </v-col>
                  <v-col cols="3" md="2" class="py-0">
                    <span class="text-subtitle-1 font-weight-bold">
                      {{ $t("price.monthlyCost") }}
                    </span>
                  </v-col>
                </v-row>
              </div>

              <div class="total-cost-content">
                <v-row
                  v-for="(addOn, index) in selectedAddOns"
                  :key="`${index}-selected-add-on`"
                >
                  <v-col cols="6" md="8" class="text-16 pl-8 py-1">
                    {{ addOn.text }}
                  </v-col>
                  <v-col cols="3" md="2" class="text-16 py-1">
                    {{ addOn.nrc }} SEK
                  </v-col>
                  <v-col cols="3" md="2" class="text-16 py-1">
                    {{ addOn.mrc }} SEK
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="8" />
                  <v-col cols="3" md="2" class="text-16 red--text font-weight-bold">
                    {{ (orderData && orderData.startCost) || totalStartCost }} SEK
                  </v-col>
                  <v-col cols="3" md="2" class="text-16 red--text font-weight-bold">
                    {{ (orderData && orderData.monthlyCost) || totalMonthlyCost }} SEK
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row v-if="isEditing">
              <v-col cols="12">
                <v-checkbox
                  v-model="isAgreeTerm"
                  :label="$t('order.agreeTerm')"
                  class="mt-0"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="justify-center mb-2">
      <v-btn
        v-if="
          !!networkInfo && (!isViewDetail || isAvailableApprove)
        "
        class="primary px-8"
        :disabled="!validForm ||
          (activeStep === 1 && !isAgreeTerm) || (isViewDetail && !personalInfo)"
        @click.native="submitForm"
      >
        {{ submitBtnText }}
      </v-btn>

      <v-btn
        v-if="isBillable"
        class="info px-8"
        @click="onCreateSubscription"
      >
        {{$t('order.createSubscription')}}
      </v-btn>

      <v-btn
        v-if="isOrderCancelable"
        class="error px-8"
        @click="onCancelOrder"
      >
        {{ $t('order.cancelOrder') }}
      </v-btn>

      <v-btn
        class="error px-8"
        @click="cancel"
      >
        {{ cancelBtnText }}
      </v-btn>
    </v-card-actions>

    <add-ssn-info-dialog
      v-model="showAddSsnInfoDialog"
      :data="ssnInfo"
      @set-ssn-info="addSsnInfo"
    />

    <update-delivery-date-dialog v-model="showDeliveryDateDialog" @update-delivery-date="updateDeliveryDate" />
  </base-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment-timezone'
  import delay from 'yoctodelay'
  import Personnummer from 'personnummer'
  import _ from 'lodash'

  import Swal from 'sweetalert2'
  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
    showQuestionDialog,
  } from '@/utils/swalDialog'
  import OfferInquiryViewer from './OfferInquiryViewer'
  import StokabOrderInfoViewer from './StokabOrderInfoViewer'
  import OpenNetworkOrderInfoViewer from './OpenNetworkOrderInfoViewer'
  import AddSsnInfoDialog from './AddSsnInfoDialog'
  import UpdateDeliveryDateDialog from './UpdateDeliveryDateDialog'
  import OrderStatusEnum from '@/utils/orderStatus'

  export default {
    metaInfo: {
      title: 'Order Network',
    },

    components: {
      OfferInquiryViewer,
      StokabOrderInfoViewer,
      OpenNetworkOrderInfoViewer,
      AddSsnInfoDialog,
      UpdateDeliveryDateDialog,
    },

    data() {
      return {
        reference: '',
        socialSecurityNumber: '',
        serviceName: '',
        selectedService: null,
        access: null,
        endCustomer: '',
        roomDescription: '',
        customerSiteReference: '',
        expectedDelivery: null,
        networkInstallDate: null,
        wantToOrderHouse: false,
        contactList: [{
          firstName: '',
          lastName: '',
          type: 'primary',
          email: '',
          confirmEmail: '',
          phoneNumber: '',
          cellPhoneNumber: '',
        }],
        contactTypeList: [],
        isSendDeliveryToLocalContactPersons: false,
        activeTerm: ['special-terms', 'about-in-house-cabling'],
        isAgreeTerm: false,
        activeStep: 1,
        orderData: null,
        addOnData: {},
        addOns: [],
        selectedAddOns: [],
        orderType: '',
        business: 'commercial',
        isValidSocialSecurityNumber: true,
        showAddSsnInfoDialog: false,
        ssnInfo: {
          firstName: '',
          lastName: '',
          street: '',
          city: '',
          zipCode: '',
          country: '',
        },
        isNoNeedSsnValidation: false,
        showDeliveryDateDialog: false,

        util: util,
        showExpectedDeliveryCalendar: false,
        showInstallDateCalenar: false,
        requiredRules: [
          (v) => !!v || this.$t('error.requiredField'),
        ],
        securityNumberRules: [
          (v) => !!v || this.isNoNeedSsnValidation || this.$t('error.requiredField'),
          (v) => !v || this.isNoNeedSsnValidation || this.isValidSocialSecurityNumber || this.$t('order.pleaseInputValidSecurityNumber'),
        ],
        requiredEmailRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => !v || !(/^mailto:/i.test(v)) || this.$t('error.excludeMailtoPrefix'),
          (v) => !v || /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        emailRules: [
          (v) => !v || !(/^mailto:/i.test(v)) || this.$t('error.excludeMailtoPrefix'),
          (v) => !v || /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        emailConfirmRules: [
          (v) => v === this.contactList[0].email || this.$t('error.confirmEmailNotMatch'),
        ],
        phoneNumberRules: [
          (v) => !v || v.startsWith('+') || /^((((0{2}?)|(\+?){1})46)|0)7[\d]{8}/.test(v) || this.$t('error.invalidPhoneNumber'),
        ],
        cellPhoneNumberRules: [
          (v) => !v || v.startsWith('+') || /^((((0{2}?)|(\+?){1})46)|0)7[\d]{8}/.test(v) || this.$t('error.invalidPhoneNumber'),
        ],
        validForm: true,
        OrderStatusEnum: OrderStatusEnum,
      }
    },

    computed: {
      ...mapGetters({
        address: 'getServiceAddress',
        network: 'getNetworkInfo',
        stokabProduct: 'getStokabProductInfo',
        manualOfferInquiry: 'getManualOfferInquiry',
        availableService: 'getAvailableService',
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
        selectedAccess: 'getSelectedAccess',
        selectedStokabPoint: 'getSelectedStokabPoint',
      }),

      serviceAddress() {
        return (this.orderData && this.orderData.address) || this.address
      },

      networkInfo() {
        return (this.orderData && this.orderData.networkInfo) || this.network
      },

      serviceInfoText() {
        if (!this.networkInfo) {
          return ''
        }

        return `${this.$t('price.networkSpeed', {
          speed: this.networkInfo.speed,
        })} ${this.networkInfo.type} / ${this.networkInfo.term} ${this.$t(
          'order.month'
        )}`
      },

      // for normal quote, we will use networkInfo.start
      // for manual quote, we shoudl calculcate the price from total and selected addons
      networkStartCost() {
        if (!this.orderData || !this.orderData.id) {
          return this.networkInfo && this.networkInfo.start
        }

        return this.orderData.orderType === 'automatic'
          ? this.networkInfo && this.networkInfo.start
          : Number(this.orderData.startCost) - this.selectedAddOnStartCost
      },

      networkMonthlyCost() {
        if (!this.orderData || !this.orderData.id) {
          return this.networkInfo && this.networkInfo.price
        }

        return this.orderData.orderType === 'automatic'
          ? this.networkInfo && this.networkInfo.price
          : Number(this.orderData.monthlyCost) - this.selectedAddOnMonthlyCost
      },

      selectedAddOnStartCost() {
        let startCost = 0

        for (const item of this.selectedAddOns) {
          startCost += Number(item.nrc)
        }

        return startCost
      },

      totalStartCost() {
        let startCost = (this.networkInfo && this.networkInfo.start) || 0

        startCost += this.selectedAddOnStartCost

        return startCost
      },

      selectedAddOnMonthlyCost() {
        let startCost = 0

        for (const item of this.selectedAddOns) {
          startCost += Number(item.mrc)
        }

        return startCost
      },

      totalMonthlyCost() {
        let monthlyCost = (this.networkInfo && this.networkInfo.price) || 0

        monthlyCost += this.selectedAddOnMonthlyCost

        return monthlyCost
      },

      addressText() {
        if (!this.serviceAddress) {
          return ''
        }

        let fullAddress = util.getFullAddress({
          street: this.serviceAddress.street,
          street_number: this.serviceAddress.streetNumber,
          letter: this.serviceAddress.streetLetter,
          postalcode: this.serviceAddress.postalCode,
          locality: this.serviceAddress.locality,
        })

        if (!this.selectedAccess || (!this.selectedAccess.mduApartmentNumber && !this.selectedAccess.mduDistinguisher)) {
          return fullAddress
        }

        if (this.selectedAccess.mduApartmentNumber && this.selectedAccess.mduDistinguisher) {
          fullAddress = `${fullAddress} (${this.selectedAccess.mduApartmentNumber}/${this.selectedAccess.mduDistinguisher})`
        } else {
          fullAddress = `${fullAddress} (${this.selectedAccess.mduApartmentNumber || this.selectedAccess.mduDistinguisher})`
        }

        return fullAddress
      },

      submitBtnText() {
        if (this.activeStep === 1) {
          return this.$t('order.order')
        } else if (this.activeStep === 2) {
          return this.$t('order.signWithBankId')
        } else if (this.activeStep === 4) {
          return this.$t('order.approve')
        }

        return ''
      },

      cancelBtnText() {
        if (this.activeStep === 1) {
          return this.$t('common.cancel')
        } else {
          return this.$t('common.back')
        }
      },

      isEditing() {
        return this.activeStep === 1
      },

      isOrdering() {
        return this.activeStep === 3
      },

      offerInquiryProducts() {
        return (
          (this.orderData &&
            this.orderData.offerInquiry &&
            this.orderData.offerInquiry.products) ||
          []
        )
      },

      stokabOrderInfo() {
        if (
          !this.orderData ||
          !this.orderData.orderInfo ||
          !this.orderData.orderInfo.products
        ) {
          return []
        }

        if (this.orderData.network.network !== 'STOKAB') {
          return []
        }

        return this.orderData.orderInfo.products || []
      },

      openNetworkOrderInfo() {
        if (!this.orderData || !this.orderData.orderInfo) {
          return null
        }

        if (this.orderData.network?.network === 'ZITIUS' && this.orderData.orderType === 'web-order') {
          return {
            ...this.orderData.orderInfo,
            spReference: this.orderData.spReference,
          }
        }

        return this.orderData.orderInfo
      },

      orderStatus() {
        if (!this.orderData) {
          return ''
        }

        if (this.orderData.status === OrderStatusEnum.REQUEST) {
          return this.$t('order.request')
        }

        if (this.orderData.status === OrderStatusEnum.PENDING) {
          return this.$t('order.pending')
        }

        if (this.orderData.status === OrderStatusEnum.APPROVED) {
          return this.$t('order.approved')
        }

        if (this.orderData.status === OrderStatusEnum.ADMIN_APPROVED) {
          return this.$t('order.adminApproved')
        }

        if (this.orderData.status === OrderStatusEnum.REJECTED) {
          return this.$t('order.rejected')
        }

        if (this.orderData.status === OrderStatusEnum.DELIVERED) {
          if (this.orderData.subscriptionId) {
            return `${this.$t('order.delivered')} - ${this.$t('order.billed')}`
          }

          return this.$t('order.delivered')
        }

        if (this.orderData.status === OrderStatusEnum.CANCELED) {
          return this.$t('order.canceled')
        }
      },

      isAvailableApprove() {
        if (!this.orderData) {
          return false
        }

        if (!this.isAdminUser && !this.isPartnerAdminUser) {
          return false
        }

        if (this.isAdminUser && this.orderData.status !== OrderStatusEnum.REQUEST) {
          return false
        }

        if (this.isPartnerAdminUser && this.orderData.status !== OrderStatusEnum.ADMIN_APPROVED) {
          return false
        }

        return util.isSuccessInquiry(this.offerInquiryProducts)
      },

      isAllowUpdatingDeliveryDate() {
        if (!this.orderData) {
          return false
        }

        if (!this.isAdminUser && !this.isPartnerAdminUser) {
          return false
        }

        if (this.orderData.status !== OrderStatusEnum.REQUEST && this.orderData.status !== OrderStatusEnum.PENDING) {
          return false
        }

        return true
      },

      isViewDetail() {
        return this.activeStep === 4
      },

      isApprovedByAdmin() {
        return this.orderData && this.orderData.status === 5
      },

      isCommercial() {
        return this.business === 'commercial'
      },

      minDeliveryDate() {
        if (this.selectedService) {
          return this.selectedService.available === 'YES' || this.selectedService.available === 'NO' ? moment().format('YYYY-MM-DD') : moment(this.selectedService.available).format('YYYY-MM-DD')
        }

        return moment().format('YYYY-MM-DD')
      },

      personalInfo() {
        return this.orderData?.personalInfo
      },

      isOrderCancelable() {
        if (!this.orderData || !this.orderData.id) {
          return false
        }

        return (this.orderData.status !== OrderStatusEnum.DELIVERED) && (this.orderData.status !== OrderStatusEnum.REJECTED) && (this.orderData.status !== OrderStatusEnum.CANCELED)
      },

      isBillable() {
        if (!this.orderData) {
          return false
        }

        if (!this.isAdminUser) {
          return false
        }

        if (this.orderData.subscriptionId) {
          return false
        }

        return this.orderData.status === OrderStatusEnum.DELIVERED
      }
    },

    watch: {
      addOnData: {
        deep: true,
        immediate: true,
        handler(value) {
          if (this.orderId) {
            return
          }

          this.selectedAddOns = []
          for (const [index, addOn] of Object.entries(this.addOns)) {
            const selectedValue = value[`value${index}`]

            if (!selectedValue) {
              continue
            }

            if (addOn.type === 'checkbox') {
              this.selectedAddOns.push({
                text: addOn.text,
                nrc: addOn.nrc,
                mrc: addOn.mrc,
              })
            } else {
              const option = addOn.detailAddOns[Number(selectedValue) - 1]
              this.selectedAddOns.push({
                text: option.text,
                nrc: option.nrc,
                mrc: option.mrc,
              })
            }
          }
        },
      },
    },

    async mounted() {
      this.contactTypeList = [
        {
          id: 'primary',
          label: this.$t('order.primaryInstallationContact'),
        },
        {
          id: 'landlord',
          label: this.$t('order.landlord'),
        },
        {
          id: 'other',
          label: this.$t('order.other'),
        },
      ]

      this.business = this.$route.query.business || 'commercial'
      this.selectedService = this.availableService
      const grandidsession = this.$route.query.grandidsession || ''
      if (grandidsession) {
        this.order(grandidsession)
        return
      }

      const expectedDelivery = this.$route.query.expectedDelivery || ''

      if (expectedDelivery) {
        this.expectedDelivery = new Date(expectedDelivery)
      }

      this.orderType = this.$route.query.orderType || 'automatic'

      const orderId = this.$route.params.orderId

      if (orderId) {
        await this.loadOrderInfo(orderId)
      }

      if (this.isEditing && this.networkInfo?.id) {
        this.loadProductInfo()
      }

      const expectedDatepickerElement = document.querySelector(
        '.order-price .expected-delivery input'
      )
      if (expectedDatepickerElement) {
        expectedDatepickerElement.required = true
      }

      await delay(100)
      const wantedDatepickerElement = document.querySelector(
        '.order-price .wanted-delivery input'
      )
      if (wantedDatepickerElement) {
        wantedDatepickerElement.required = true
      }
    },

    methods: {
      ...mapActions({
        signWithBankId: 'signWithBankId',
        createOrder: 'createOrder',
        fetchOrderInfo: 'fetchOrderInfo',
        approveOrder: 'approveOrder',
        cancelOrder: 'cancelOrder',
        fetchProduct: 'fetchProduct',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getSubscriptionCustomer: 'getSubscriptionCustomer',
        createOrderSubscription: 'createOrderSubscription',
        addPersonalData: 'addPersonalData',
        updateOrderInfo: 'updateOrderInfo',
      }),

      disabledDate(date) {
        return util.formatDateTime(date) < util.formatDateTime(new Date())
      },

      submitForm() {
        if (this.activeStep === 1) {
          const isValidForm = this.$refs.form.validate()

          if (!isValidForm) {
            return
          }

          const isValidContactList = this.isValidContactList()

          if (!isValidContactList) {
            return
          }

          this.activeStep = 2
        } else if (this.activeStep === 2) {
          this.signWithPersonalNumber()
        } else if (this.activeStep === 4) {
          this.isNoNeedSsnValidation = true
          const isValidForm = this.$refs.form.validate()

          if (!isValidForm) {
            return
          }

          this.approveOrderInfo()
        }
      },

      isValidContactList() {
        const primaryContacts = this.contactList.filter(
          (item) => item.type === 'primary'
        )

        if (primaryContacts.length === 0) {
          showErrorDialog(
            this.$t('common.error'),
            this.$t('order.choosePrimaryContact')
          )
          return false
        }

        if (primaryContacts.length > 1) {
          showErrorDialog(
            this.$t('common.error'),
            this.$t('order.chooseOnlyOnePrimaryContact')
          )
          return false
        }

        if (!this.isCommercial) {
          if (primaryContacts[0].email !== primaryContacts[0].confirmEmail) {
            showErrorDialog(
              this.$t('common.error'),
              'Email confirmation is invalid'
            )
            return false
          }
        }

        return true
      },

      async signWithPersonalNumber() {
        const parsedSocialSecurityNumber =
          this.socialSecurityNumber && !this.isNoNeedSsnValidation &&
          Personnummer.parse(this.socialSecurityNumber);

        if (parsedSocialSecurityNumber) {
          this.socialSecurityNumber = parsedSocialSecurityNumber.format(true);
        }

        const formData = {
          networkInfo: this.network,
          address: this.address,
          reference: this.reference,
          endCustomer: this.endCustomer,
          roomDescription: this.roomDescription,
          customerSiteReference: this.customerSiteReference,
          expectedDelivery: util.formatDateTime(this.expectedDelivery, 'YYYY-MM-DD'),
          wantToOrderHouse: this.wantToOrderHouse,
          contactInfo: {
            isSendDeliveryToLocalContactPersons:
              this.isSendDeliveryToLocalContactPersons,
            contactList: this.contactList,
          },
          addOnData: this.selectedAddOns,
          startCost: this.totalStartCost,
          monthlyCost: this.totalMonthlyCost,
          stokabProduct: this.stokabProduct,
          orderType: this.orderType,
          offerInquiry: this.manualOfferInquiry,
          service: this.selectedService,
          access: this.selectedAccess,
          socialSecurityNumber: this.socialSecurityNumber,
          business: this.business,
        }

        const questionIconHtml = '<i class="v-icon mdi mdi-help-circle question" />'
        const btnResponse = await Swal.fire({
          text: this.$t('order.pleaseInputPersonalNumber'),
          input: 'text',
          iconHtml: questionIconHtml,
          inputPlaceholder: this.$t('order.pleaseInputPersonalNumber'),
          inputValue: this.socialSecurityNumber,
          inputValidator: (value) => {
            return value ? null : this.$t('order.pleaseInputPersonalNumber')
          },
          validationMessage: this.$t('order.pleaseInputPersonalNumber'),
          type: 'question',
          showDenyButton: this.isAdminUser || this.isPartnerAdminUser,
          showCancelButton: true,
          denyButtonText: 'Order without BankId',
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#3f51b5',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.no'),
        })

        if (btnResponse.isDismissed) {
          return
        }

        const personalNumber = btnResponse.value

        formData.personalNumber = personalNumber

        // Order product directly without bankId for admin
        if (!personalNumber) {
          localStorage.setItem('orderData', JSON.stringify(formData))
          this.order(null)
          return
        }

        let orderInfo = `${this.$t('order.youAreSigning', {
          street: this.serviceAddress.street,
          streetNumber: this.serviceAddress.streetNumber,
          city: this.serviceAddress.city,
          deliveryDate: formData.expectedDelivery,
        })}\n\n`
        orderInfo += `${this.$t('order.leasedLine', {
          downSpeed: this.networkInfo.downSpeed,
          upSpeed: this.networkInfo.upSpeed,
        })}\n`

        if (this.selectedAddOns.length > 0) {
          for (const item of this.selectedAddOns) {
            orderInfo += `${item.text} (NRC: ${item.nrc} SEK, MRC: ${item.mrc} SEK)\n`
          }
        }

        orderInfo += '\n'
        orderInfo += `${this.$t('order.totalCostPerMonthAndInitially', {
          monthlyCost: this.totalMonthlyCost,
          startCost: this.totalStartCost,
        })}\n\n`
        orderInfo += `${this.$t('order.contractTerm', {
          months: this.networkInfo.term,
        })}\n\n`
        orderInfo += this.$t('order.cancellationAccordingTo')

        let redirectUrl = ''

        this.setLoadingText(`${this.$t('manualOffer.signingWithBankId', {
          bankId: personalNumber,
        })}...`)
        this.setLoading(true)
        try {
          const payload = {
            personalNumber,
            orderInfo,
          }

          const response = await this.signWithBankId(payload)

          redirectUrl = response.redirectUrl
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        if (redirectUrl) {
          localStorage.setItem('orderData', JSON.stringify(formData))
          location.href = redirectUrl
        }

        this.setLoading(false)
      },

      cancel() {
        if (this.activeStep === 1) {
          this.$router.go(-1)
        } else if (this.activeStep === 2) {
          this.activeStep = 1
        } else if (this.activeStep === 4) {
          this.$router.push('/orders')
        }
      },

      addContact() {
        this.contactList.push({
          firstName: '',
          lastName: '',
          type: 'other',
          email: '',
          phoneNumber: '',
        })
      },

      deleteContact(index) {
        this.contactList.splice(index, 1)
      },

      async loadProductInfo() {
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchProduct(this.networkInfo.id)
          this.addOns = response.networkAddons
            .map((networkAddOn) => networkAddOn.addOn)
            .filter((addOn) => addOn.isActive)
        } catch (error) {
          // No need to do anything
        }
        this.setLoading(false)
      },

      async order(sessionId) {
        this.orderData = JSON.parse(localStorage.getItem('orderData'))

        if (!this.orderData) {
          return
        }

        this.formatOrderData()
        this.activeStep = 4

        this.setLoadingText(`${this.$t('order.creatingOrder')}...`)
        this.setLoading(true)
        try {
          const response = await this.createOrder({
            ...this.orderData,
            sessionId,
          })

          this.setLoading(false)
          await showSuccessDialog(this.$t('order.networkOrderedSuccessfully'))
          localStorage.removeItem('orderData')

          if (this.$route.name === 'PublicOrderProduct') {
            this.$router.push('/search')
          } else {
            // this.$router.push('/order-price/' + response.orderId)
            this.$router.push('/orders')
          }
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async loadOrderInfo(orderId) {
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchOrderInfo(orderId)
          this.orderData = {
            ...response,
            contactInfo: {
              contactList: response.orderLocalContactList,
              isSendDeliveryToLocalContactPersons:
                response.isSendDeliveryToLocalContactPersons,
            },
            address: {
              street: response.street,
              streetNumber: response.streetNumber,
              streetLetter: response.streetLetter,
              postalCode: response.postalCode,
              locality: response.locality,
            },
            networkInfo: response.network,
            addOnData: response.addOns || [],
            offerInquiry: response.offerInquiry,
          }
          this.activeStep = 4
          this.formatOrderData()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      formatOrderData() {
        this.selectedAddOns = this.orderData.addOnData
        this.reference = this.orderData.reference
        this.endCustomer = this.orderData.endCustomer
        this.roomDescription = this.orderData.roomDescription
        this.customerSiteReference = this.orderData.customerSiteReference
        this.expectedDelivery = new Date(this.orderData.expectedDelivery)
        this.wantToOrderHouse = this.orderData.wantToOrderHouse
        this.contactList = this.orderData.contactInfo.contactList
        this.isSendDeliveryToLocalContactPersons =
          this.orderData.contactInfo.isSendDeliveryToLocalContactPersons
        this.networkInstallDate = this.isViewDetail && this.orderData.networkInstallDate
          ? new Date(this.orderData.networkInstallDate)
          : null
        this.socialSecurityNumber = this.orderData.socialSecurityNumber
        this.business = this.orderData.business
        this.selectedService = this.orderData.service
        this.access = this.orderData.access
      },

      async approveOrderInfo() {
        this.setLoadingText(`${this.$t('order.approvingOrder')}...`)
        this.setLoading(true)
        try {
          const payload = {
            orderId: this.orderData.id,
            // networkInstallDate: util.formatDateTime(this.networkInstallDate, 'YYYY-MM-DD'),
          }

          await this.approveOrder(payload)

          this.setLoading(false)
          await showSuccessDialog(this.$t('order.approveOrderSuccessfully'))

          this.$router.push('/orders')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      getInquiryAddress(item) {
        const address = (item && item.address) || ''

        if (!address) {
          return ''
        }

        return util.getFullAddress({
          street: address.street,
          street_number: address.number,
          letter: address.littera,
          locality: address.city,
          postalcode: address.postalCode,
        })
      },

      validateSocialSecurityNumber() {
        if (!this.socialSecurityNumber) {
          this.isValidSocialSecurityNumber = false
          return
        }

        this.isValidSocialSecurityNumber = Personnummer.valid(
          this.socialSecurityNumber
        )
      },

      formatAvailableDate(available) {
        if (available === 'YES' || available === 'NO') {
          return available
        }

        return util.formatDateTime(available, 'YYYY-MM-DD')
      },

      async onCreateSubscription() {
        this.setLoadingText('Creating order subscription...')
        this.setLoading(true)
        try {
          const response = await this.getSubscriptionCustomer(this.socialSecurityNumber)
          if (response.customer) {
            this.setLoading(false)
            const btnResponse = await showQuestionDialog(
              this.$t('order.areYouSureToBill'),
              this.$t('order.customerExist'),
              this.$t('common.yes'),
              this.$t('common.abort'),
            )

            if (!btnResponse.value) {
              return
            }
          }
          this.setLoading(true)

          await this.createOrderSubscription({
            orderId: this.orderData.id,
          })

          this.setLoading(false)
          await showSuccessDialog(this.$t('order.createSubscriptionSuccessfully'))
          this.$router.push('/orders')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async onCancelOrder() {
        const btnResponse = await showQuestionDialog(
          `${this.$t('order.cancelOrder')}?`,
          this.$t('order.areYouSureToCancelOrder'),
          this.$t('common.cancel')
        )

        if (!btnResponse.value) {
          return
        }

        this.setLoadingText(this.$t('order.cancelingOrder'))
        this.setLoading(true)
        try {
          await this.cancelOrder(this.orderData.id)

          this.setLoading(false)
          await showSuccessDialog(this.$t('order.canceledOrderSuccessfully'))

          this.$router.push('/orders')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      onAddPersonalInfo() {
        this.ssnInfo = {
          socialSecurityNumber: this.orderData.socialSecurityNumber || '',
          firstName: this.personalInfo?.basic?.firstName || '',
          lastName: this.personalInfo?.basic?.lastName || '',
          street: this.personalInfo?.basic?.street || '',
          city: this.personalInfo?.basic?.city || '',
          zipCode: this.personalInfo?.basic?.zipCode || '',
          country: this.personalInfo?.basic?.country || '',
        }

        this.showAddSsnInfoDialog = true
      },

      async addSsnInfo(data) {
        this.showAddSsnInfoDialog = false

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          await this.addPersonalData({
            orderId: this.orderData.id,
            ...data,
          })

          await this.loadOrderInfo(this.orderData.id)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async updateDeliveryDate(date) {
        this.showDeliveryDateDialog = false

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          await this.updateOrderInfo({
            id: this.orderData.id,
            expectedDelivery: date,
          })
          this.orderData.expectedDelivery = date
          this.expectedDelivery = new Date(date)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      onChangeEmail(e) {
        if (this.contactList[0].email && this.contactList[0].email.startsWith('mailto:')) {
          this.contactList[0].email = this.contactList[0].email.replace(/^mailto:/i, '')
        }
      },
    }
  }
</script>

<style lang="scss">
.order-price {
  .underline {
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }

  .theme--light.v-select .v-select__selection--disabled,
  .theme--light.v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
