<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="400"
  >
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="'Update Delivery Date'"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-menu
            v-model="showExpectedDeliveryCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedDelivery"
                :label="$t('price.expectedDelivery')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="requiredRules"
              />
            </template>
            <v-date-picker
              v-model="expectedDelivery"
              :min="minDeliveryDate"
              @input="showExpectedDeliveryCalendar = false"
            />
          </v-menu>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mt-2 pb-4">
        <v-btn
          class="primary px-10 mr-4"
          @click="updateDeliveryDate"
        >
          {{ $t("common.update") }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: "UpdateDeliveryDateDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    minDeliveryDate() {
      return moment().format('YYYY-MM-DD')
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: true,
      expectedDelivery: null,
      showExpectedDeliveryCalendar: false,
    }
  },

  methods: {
    updateDeliveryDate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit('update-delivery-date', this.expectedDelivery)
    },
  }
}
</script>
