<template>
  <div class="open-network-order-info-viewer">
    <div class="text-subtitle-1 font-weight-bold my-2 mt-6 underline">
      {{ networkType }} {{ $t("order.orderInfo") }}
      (
        <span class="red--text">
          {{ status }}
        </span>
      )
    </div>

    <v-row>
      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.orderId") }}</label>
        <div>{{ orderId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.status") }}</label>
        <div class="red--text font-weight-bold">{{ orderInfo.state }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.operation") }}</label>
        <div>{{ orderInfo.operation }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.service") }}</label>
        <div>{{ orderInfo.service }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.subscriptionId") }}</label>
        <div>{{ orderInfo.subscriptionId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.forcedTakeover") }}</label>
        <div>{{ orderInfo.forcedTakeover ? $t("common.yes") : $t("common.no") }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.spReference") }}</label>
        <div>{{ orderInfo.spReference }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.spSubscriptionId") }}</label>
        <div>{{ orderInfo.spSubscriptionId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.requestedDateTime") }}</label>
        <div>{{ formatDateTime(orderInfo.requestedDateTime, "MMM DD, YYYY HH:mm") }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.expectedCompletionDate") }}</label>
        <div>{{ formatDateTime(orderInfo.expectedCompletionDate, "MMM DD, YYYY HH:mm") }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import moment from 'moment-timezone'

  import util from '@/utils'

  export default {
    props: {
      orderInfo: {
        type: Object,
        default: () => ({}),
      },

      networkType: {
        type: String,
        default: '',
      },

      status: {
        type: String,
        default: '',
      },
    },

    computed: {
      orderId() {
        if (this.orderInfo.id) {
          return this.orderInfo.id
        }

        const pathItems = (this.orderInfo.path || '').split('/')

        return pathItems[pathItems.length - 1]
      }
    },

    methods: {
      formatDateTime(date, format) {
        if (!date) {
          return
        }

        return util.formatDateTime(moment.utc(date).toDate(), format)
      },
    },
  }
</script>
