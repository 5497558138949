<template>
  <div class="stokab-order-info-viewer">
    <div class="text-subtitle-1 font-weight-bold my-2 mt-6 underline">
      {{ $t("order.orderInfo") }}
    </div>
    <v-row
      v-for="(item, index) in orderInfo"
      :key="index"
    >
      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("common.name") }}</label>
        <div>{{ item.product.name }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.status") }}</label>
        <div class="red--text font-weight-bold">{{ item.product.name }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.orderId") }}</label>
        <div>{{ item.orderId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.inquiryNumber") }}</label>
        <div>{{ item.inquiryNumber }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.responsiblePerson") }}</label>
        <div>{{ item.responsiblePerson }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.responsiblePersonEmail") }}</label>
        <div>{{ item.responsiblePersonEmail }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.offerValidUntilDate") }}</label>
        <div>{{ util.formatDateTime(item.offerValidUntilDate) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.deliverydurationDays") }}</label>
        <div>{{ item.deliverydurationDays }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.preferredDeliveryDate") }}</label>
        <div>{{ util.formatDateTime(item.preferredDeliveryDate) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.deliveryDate") }}</label>
        <div>{{ util.formatDateTime(item.deliveryDate) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("price.startCost") }}</label>
        <div>{{ item.product.price.oneTimeFee }} SEK</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("price.monthlyCost") }}</label>
        <div>{{ item.product.price.monthlyFee }} SEK</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.contractPeriodYears") }}</label>
        <div>{{ item.product.price.contractPeriodYears }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.doneDateTime") }}</label>
        <div>{{ util.formatDateTime(item.product.doneDateTime) }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import util from '@/utils'

  export default {
    props: {
      orderInfo: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        util: util,
      }
    },
  }
</script>
